<template>
  <div class="order-list">
    <div class="order-item" v-for="(item,index) in $store.state.orderList" :key="item.order_id" @click="seeDetails(index)">
      <div class="title" :style="{color:(item.exa_status===2||item.exa_status===3)?'#999':''}">
        {{['待处理','换机完成','已取消','已作废'][item.exa_status]}}</div>
      <div class="order-info">
        <div class="info-item">
          <div class="info-name">订单编号：</div>
          <div class="info-value">{{ item.order_sn }}</div>
        </div>
        <div class="info-item">
          <div class="info-name">用户姓名：</div>
          <div class="info-value">{{ item.name }}</div>
        </div>
        <div class="info-item">
          <div class="info-name">用户电话：</div>
          <div class="info-value">{{ item.phone }}</div>
        </div>
        <div class="info-item">
          <div class="info-name">提交时间：</div>
          <div class="info-value">{{ item.add_time }}</div>
        </div>
      </div>
      <div class="new-phone-info">
        <div class="tag">
          新机信息
        </div>
        <img class="img" :src="item.new_img" alt="">
        <div class="detail">
          <div class="name">{{ item.new_model }}</div>
          <div class="prop">{{ item.new_ram }} {{ item.new_colour }}</div>
<!--          <div class="price"><span>迭代补差</span> <span>{{item.diff_money}}元</span></div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  methods:{
    seeDetails(index) {
      let order=this.$store.state.orderList[index]
      this.$router.push({
        path: 'orderDetail',
        query:{
          order_id:order.order_id
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.order-list {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.order-item {
  width: calc(100vw - 60px);
  background: #FFFFFF;
  box-shadow: 0 4px 21px 9px rgba(196, 197, 197, 0.4);
  border-radius: 20px;
  margin: 30px auto;
  padding-bottom: 10px;
}

.title {
  height: 72px;
  line-height: 72px;
  font-size: 32px;
  font-weight: bold;
  color: @base-color;
  padding-left: 30px;

  &:after {
    content: '';
    display: block;
    width: calc(100vw - 130px);
    height: 2px;
    background: #F9F9F9;
    border-radius: 1px;
  }
}

.order-info {
  padding: 30px;

  .info-item {
    display: flex;
    align-items: center;
    height: 60px;
    line-height: 60px;

    .info-name {
      font-size: 28px;
      font-weight: 500;
      color: #999999;
    }

    .info-value {
      font-size: 28px;
      font-weight: 500;
      color: #333333;
    }
  }
}

.new-phone-info {
  width: calc(100vw - 80px);
  height: 152px;
  background: #FDF5F5;
  border-radius: 16px;
  margin: auto;
  padding: 10px;
  display: flex;
  position: relative;

  .tag {
    height: 32px;
    background: #FFB016;
    border-radius: 16px 16px 16px 0;
    font-size: 22px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 0 10px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .img {
    width: 136px;
    height: 136px;
    background: #FFFFFF;
    border-radius: 10px;
  }

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 26px;

    .name {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 450px;
      margin-bottom: 20px;
    }

    .prop {
      font-size: 26px;
      font-weight: 500;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 450px;
    }
  }
}

.price {
  font-size: 24px;
  font-weight: bold;
  color: @base-color;
  border-radius: 30px;
  border: 1px solid @base-color;
  background: #fff;
  width: fit-content;

  span {
    display: inline-block;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
  }

  span:first-child {
    background: @base-color;
    color: #fff;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
;

  span:last-child {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    min-width: 100px;
  }
}
</style>
